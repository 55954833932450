// index.js
import $ from 'jquery'; // Import jQuery
import { inject } from '@vercel/analytics'; // Import Vercel Analytics
import 'bootstrap'; // Import Bootstrap (this will include Popper.js)
import { createPopper } from '@popperjs/core';
import 'magnific-popup';
import 'wowjs';

// Make jQuery globally accessible
window.$ = window.jQuery = $;

// Inject Vercel Analytics into your static site
inject();